import React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { Loader2Icon } from 'lucide-react';

import { type BaseProps } from '../utils';

import { cn } from '@/lib';

const iconButtonVariants = cva(
  [
    // Base font
    'text-icon-accent',
    // Base appearance
    'rounded-sm',
    'transition-colors',
    'duration-300',
    'flex',
    'items-center',
    'justify-center',
    // Focus
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-focus-default',
    'focus-visible:ring-offset-2',
    // Disabled
    'disabled:pointer-events-none',
    'disabled:border-none',
    '[&:disabled>svg]:text-onInteractive-disabled',
    // Svg icon
    '[&>svg]:size-6',
  ],
  {
    variants: {
      variant: {
        ghost: [
          'active:bg-surface-subtle-pressed',
          'hover:bg-surface-subtle-hovered',
        ],
        outline: [
          'border-xs',
          'bg-surface-subtle-default',
          'border-subtle',
          'active:bg-surface-subtle-pressed',
          'hover:bg-surface-subtle-hovered',
        ],
        subtle: [
          'bg-interactive-subtle-default',
          'active:bg-interactive-subtle-pressed',
          'hover:bg-interactive-subtle-hovered',
          '[&>svg]:text-icon-onInteractive-subtle',
          'disabled:text-icon-disabled',
          'disabled:bg-interactive-disabled',
          // Alert
          '[&>svg]:data-[alert=true]:text-icon-onInteractive-alert-subtle',
          'data-[alert=true]:bg-interactive-alert-subtle-default',
          'data-[alert=true]:active:bg-interactive-alert-subtle-pressed',
          'data-[alert=true]:hover:bg-interactive-alert-subtle-hovered',
        ],
        accent: [
          'bg-interactive-accent-default',
          'active:bg-interactive-accent-pressed',
          'hover:bg-interactive-accent-hovered',
          '[&>svg]:text-icon-onInteractive-accent',
          'disabled:text-icon-disabled',
          'disabled:bg-interactive-disabled',
          // Alert
          'data-[alert=true]:bg-interactive-alert-accent-default',
          'data-[alert=true]:active:bg-interactive-alert-accent-pressed',
          'data-[alert=true]:hover:bg-interactive-alert-accent-hovered',
        ],
      },
      size: {
        lg: ['size-14', '[&>svg]:size-6'],
        md: ['size-12', '[&>svg]:size-6'],
        sm: ['size-10', '[&>svg]:size-6'],
        xs: ['size-8', '[&>svg]:size-5'],
        xxs: ['size-6', '[&>svg]:size-5'],
      },
    },
    defaultVariants: {
      variant: 'ghost',
      size: 'sm',
    },
  },
);

export interface IconButtonProps
  extends BaseProps<'button', typeof iconButtonVariants> {
  asChild?: boolean;
  loading?: boolean;
  alert?: boolean;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      asChild = false,
      className,
      children,
      variant,
      size,
      alert = false,
      loading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        type="button"
        ref={ref}
        data-alert={alert}
        className={cn(iconButtonVariants({ variant, size }), className)}
        {...props}
      >
        {loading ? <Loader2Icon className="animate-spin" /> : children}
      </Comp>
    );
  },
);

IconButton.displayName = 'IconButton';

export { IconButton, iconButtonVariants };
