import * as React from 'react';

import { cva } from 'class-variance-authority';

import { Body } from '../typography/body';
import { Heading } from '../typography/heading';
import { type BaseProps } from '../utils';

import { cn } from '@/lib/utils';

export const alertVariants = cva(
  [
    // layout
    'gap-2',
    'p-2',
    'flex',
    'items-start',
    // appearance
    'w-full',
    'flex-col',
    'md:flex-row',
    'md:items-center',
    'rounded-sm',
    'border-xs',
    // tailwind group
    'group',
  ],
  {
    variants: {
      severity: {
        info: [
          'bg-surface-feedback-info-subtle',
          'text-feedback-info',
          'border-feedback-info',
        ],
        warning: [
          'bg-surface-feedback-warning-subtle',
          'text-feedback-warning',
          'border-feedback-warning',
        ],
        danger: [
          'bg-surface-feedback-alert-subtle',
          'text-feedback-alert',
          'border-feedback-alert',
        ],
        success: [
          'bg-surface-feedback-success-subtle',
          'text-feedback-success',
          'border-feedback-success',
        ],
      },
    },
    defaultVariants: {
      severity: 'info',
    },
  },
);

export interface AlertProps extends BaseProps<'div', typeof alertVariants> {}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, severity, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(alertVariants({ severity }), className)}
      data-severity={severity}
      role="alert"
      {...props}
    />
  ),
);
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <Heading
    variant="medium"
    size="xs"
    ref={ref}
    className={cn('flex items-center gap-2', className)}
    {...props}
  />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>((props, ref) => <Body ref={ref} size="xs" {...props} />);
AlertDescription.displayName = 'AlertDescription';

const AlertInfo = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      [
        // layout
        'flex',
        'gap-2',
        // icon appearance
        '[&>svg]:h-5',
        '[&>svg]:w-5',
        '[&>svg]:shrink-0',
        // info
        'group-data-[severity=info]:[&_svg]:text-icon-feedback-info',
        // warning
        'group-data-[severity=warning]:[&_svg]:text-icon-feedback-warning',
        // success
        'group-data-[severity=success]:[&>svg]:text-icon-feedback-success',
        // danger
        'group-data-[severity=danger]:[&>svg]:text-icon-feedback-alert',
      ],
      className,
    )}
    {...props}
  />
));
AlertInfo.displayName = 'AlertInfo';

const AlertTextContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex flex-col justify-center', className)}
    {...props}
  />
));
AlertTextContent.displayName = 'AlertTextContent';

const AlertActions = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-wrap items-center gap-3 pl-6 md:ml-auto md:flex-nowrap',
      className,
    )}
    {...props}
  />
));
AlertActions.displayName = 'AlertActions';

export {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertInfo,
  AlertTextContent,
  AlertActions,
};
